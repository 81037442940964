
import * as React from 'react';
import ResponseTabs from '../ResponseTabs/ResponseTabs';

const ResponseContent = props => {
    const {response, responseHeader, requestJson, jsonFunction, ...others } = props;
    return (
        <>
            <ResponseTabs response={response} responseHeader={responseHeader} requestJson={requestJson} jsonFunction={jsonFunction}/>
        </>
    );
}

export default ResponseContent;