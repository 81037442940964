import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';



 const SideBarBottom= ({children}) =>{
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: '0',
                left: '0',
                height: '100px',
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    p: '10px',
                    m: 0,
                    width: 339
                },
            }}
        >
            <Paper square elevation={3}>
                {children}
            </Paper>
        </Box>
    );
}

export default SideBarBottom;