import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TypeUrl from '../TripleInput/TripleInput';
import { Stack } from '@mui/system';
import { Button } from '@mui/material';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LoopTabs = (props) => {
    const { children, singlePropUpdateFunc, objPropUpdateFunc, levelOneArray, levelTwoArray, ...others } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="loop tabs"
                    scrollButtons
                    allowScrollButtonsMobile>
                    <Tab label="Level 1" {...a11yProps(0)} />
                    <Tab label="Level 2" {...a11yProps(1)} />                                    
                </Tabs>
            </Box>
            
            <TabPanel value={value} index={0} sx={{
                backgroundColor: "lightgreen",
              }}>
                <TypeUrl singlePropUpdateFunc={singlePropUpdateFunc} objPropUpdateFunc={objPropUpdateFunc} jsonArray={levelOneArray} level="level_1" />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TypeUrl singlePropUpdateFunc={singlePropUpdateFunc} objPropUpdateFunc={objPropUpdateFunc} jsonArray={levelTwoArray ? levelTwoArray : []} level="level_2" />
            </TabPanel>
        </Box>
    );
}


export default LoopTabs;