import React, { useState, useEffect, useRef } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import NewDialog from "../NewDialog/NewDialog";
import TextField from '@mui/material/TextField';
import ProjectMenu from "./ProjectMenu";
import axios from 'axios';
import PopSnackBar from "../PopSnackBar/PopSnackBar";
import { uriConfig } from "../../data/uri";
import { useAuth0, } from '@auth0/auth0-react';
import SideBarBottom from "../SideBarBottom/SideBarBottom";
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import BuildIcon from '@mui/icons-material/Build';
import SearchIcon from '@mui/icons-material/Search';
import HelpIcon from '@mui/icons-material/Help';
import DataTable from "../DataTable/DataTable";
import GistComponent from "../GistComponent/GistComponent";
import Popover from '@mui/material/Popover';

const drawerWidth = 340;

const SideBar = (props) => {
    const { jsonFunction, deleteCheck, saveButton, setOverlay, ...other } = props;

    const [projectNameErr, setProjectNameErr] = useState(false);
    const [token, setToken] = useState(0);
    const [caller, setCaller] = useState(null);
    const [userDetails, setUserDetails] = useState({})
    const [requestViewData, setRequestViewData] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedRequestId, setSelectedRequestId] = useState(null);

    const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();

    const dialogRef = useRef();
    const projectRef = useRef();
    const snackRef = useRef();

    const getSelection = (e) => {
        setSelectedRequestId(e.target.getAttribute('data-request'))
        // console.log(e.target.getAttribute('data-request'))
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const dataPrep = (dataSet) => {
        // removing the unwanted props
        const updatedData = dataSet.map(({ project_id, user_id, ...rest }) => rest);
        // ordering the properties in the correct order for the view
        const orderedColumns = [
            "request_id",
            "request_number",
            "request_name",
            "project_name",
            "created_by",
            "created_datetime"
        ];


        const finalData = updatedData.map((item) => {
            const orderedItem = {};
            orderedColumns.forEach((column) => {
                orderedItem[column] = item[column];
                if (column === 'created_datetime') {
                    if (item[column]) {
                        const dateTime = new Date(item[column]);
                        const isoDateTime = dateTime.toISOString().replace('T', ' ').slice(0, 19);
                        orderedItem[column] = isoDateTime;
                    } else {
                        orderedItem[column] = item[column];
                    }
                } else {
                    orderedItem[column] = item[column];
                }
            });
            return orderedItem;
        });

        return finalData
    }

    const requestViewHeaders = [
        {
            id: 'request_id',
            numeric: false,
            disablePadding: false,
            label: 'Request Id',
        },
        {
            id: 'request_number',
            numeric: true,
            disablePadding: false,
            label: 'Request Number',
        },
        {
            id: 'request_name',
            numeric: false,
            disablePadding: false,
            label: 'Request Name',
        },
        {
            id: 'project_name',
            numeric: false,
            disablePadding: false,
            label: 'Project Name',
        },
        {
            id: 'created_by',
            numeric: false,
            disablePadding: false,
            label: 'Created By',
        },
        {
            id: 'created_datetime',
            numeric: false,
            disablePadding: false,
            label: 'Created Datetime',
        },
    ];

    const comingSoonDialog = () => {
        const dialoagConfig = {
            title: 'Coming soon!',
            context: 'Sorry this feature is under development, check back another time!',
            components: <></>,
            okButton: 1,
            buttons: {
                bottom: [
                ]
            }
        };
        openDialog(dialoagConfig)
    }

    const openDocs = () => {
        const dialoagConfig = {
            title: 'Documentations',
            context: '',
            components: <GistComponent />,
            maxWidth: 'xl',
            okButton: 1,
            buttons: {
                bottom: [
                ]
            }
        };
        openDialog(dialoagConfig)
    }

    const openDialog = (config) => {
        dialogRef.current.handleOpen(config);
        setProjectNameErr(false);
    }

    const updateDialog = (config) => {
        dialogRef.current.updateModal(config)
    }

    const getAccessToken = async (tokenCaller) => {
        try {
            const token = await getAccessTokenSilently()
            setToken(token)
            //setCaller(tokenCaller)
        } catch (e) {
            snackRef.current.snackBarOpen({ 'message': e })
        }
    }

    const initialCreateProject = async () => {
        await getAccessToken()
        setCaller('createProject')
    }

    const initialGetUser = async () => {
        await getAccessToken()
        setCaller('getUser')
    }

    const initialGetRequestView = async () => {
        await getAccessToken()
        setCaller('getRequestView')
    }

    const createProject = () => {
        setProjectNameErr(true)
        setOverlay(true)
        const projectName = document.getElementById('projectName').value
        axios.post(uriConfig.url.newProject, { 'projectName': projectName }, { headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                console.log(response.data)
                snackRef.current.snackBarOpen({ 'message': "New project, '" + projectName + "', created!" })
                dialogRef.current.modalCloseAction()
                projectRef.current.updateList()
                setOverlay(false)
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
                setOverlay(false)
            });
    }

    const getCurrentUser = () => {
        const currentUid = user.sub
        //console.log("url request for user id - ", currentUid);
        // const requestName = document.getElementById('requestName').value;
        axios.get(uriConfig.url.getUser, { headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                //console.log(response.data);
                setUserDetails(response.data)
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
            });
    }

    const getRequestView = () => {
        setOverlay(true)
        axios.get(uriConfig.url.getRequestView, { headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                //console.log(response.data);                
                setRequestViewData(dataPrep(response.data))
                setOverlay(false)
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
                setOverlay(false)
            });
    }

    const inputErrorCheck = (event) => {
        const inputLength = event.target.value;
        if (inputLength.length < 5 || inputLength.length > 20) {
            setProjectNameErr(true);
        } else {
            setProjectNameErr(false);
        }
    };

    const tableConfig = {
        toolbarMenu: false,
        rowCheckBox: false,
        key: 'request_id'
    }

    const projectModalConf = {
        title: 'New Project',
        context: 'What is the name of your project?',
        components: <TextField
            autoFocus
            error={projectNameErr}
            margin="dense"
            id="projectName"
            label="Project name"
            fullWidth
            helperText="Project name needs to be between 5 and 20 characters"
            variant="filled"
            defaultValue="Project name"
            onChange={e => inputErrorCheck(e)}
        />,
        cancelButton: 1,
        buttons: {
            bottom: [
                {
                    label: 'Create',
                    disable: projectNameErr,
                    onClick: initialCreateProject
                }
            ]
        }
    };

    const requestDialogueClose = () => {
        dialogRef.current.modalCloseAction();
    }

    useEffect(() => {
        dialogRef.current.updateModal(projectModalConf);
    }, [projectNameErr]);

    useEffect(() => {
        if (caller === 'createProject') {
            createProject()
            setCaller(null)
        }
        else if (caller === 'getUser') {
            getCurrentUser()
            setCaller(null)
        }
        else if (caller === 'getRequestView') {
            getRequestView()
            setCaller(null)
        }
    }, [caller])

    useEffect(() => {
        if (isAuthenticated) {
            initialGetUser()
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (requestViewData != 0) {
            const requestTableModalConf = {
                title: 'Request details',
                context: '',
                components: <DataTable tableTitle='All requests' dataSet={requestViewData} headers={requestViewHeaders} tableConfig={tableConfig} getSelection={getSelection} />,
                okButton: 1,
                buttons: {
                    bottom: [
                    ]
                }
            };
            openDialog(requestTableModalConf)
        }
    }, [requestViewData])

    return (
        <Drawer
            variant="permanent"
            sx={{
                display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' },
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto', marginTop: '1rem', marginBottom: '100px' }}>
                <Stack spacing={2} sx={{ padding: '1rem' }} >
                    <Button variant="contained" onClick={() => openDialog(projectModalConf)} endIcon={<ControlPointIcon />}>New Project</Button>
                </Stack>
                <Divider />
                <ProjectMenu
                    jsonFunction={jsonFunction}
                    openDialog={openDialog}
                    ref={projectRef}
                    requestDialogueClose={requestDialogueClose}
                    updateDialog={updateDialog}
                    deleteCheck={deleteCheck}
                    saveButton={saveButton}
                    setOverlay={setOverlay}
                    selectedRequestId={selectedRequestId}
                />
            </Box>
            <SideBarBottom>
                {isAuthenticated ?
                    <Box>
                        <Typography
                            variant="overline"
                            display="block"
                            gutterBottom
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}>
                            <strong>{userDetails.fullname ? userDetails.fullname : user.name}</strong>
                        </Typography>
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <Typography sx={{ p: 1 }}>Email: {user.name}</Typography>
                        </Popover>
                        <Stack direction="row" spacing={1}>
                            <IconButton aria-label="search" onClick={() => initialGetRequestView()}>
                                <SearchIcon />
                            </IconButton>
                            <IconButton aria-label="settings" onClick={() => openDocs()}>
                                <HelpIcon />
                            </IconButton>
                            <IconButton aria-label="settings" onClick={() => comingSoonDialog()}>
                                <BuildIcon />
                            </IconButton>
                        </Stack>
                    </Box> :
                    <Box>
                        <Skeleton width="100%" height={70} />
                        <Skeleton width="100%" height={70} />
                    </Box>
                }
            </SideBarBottom>
            <NewDialog ref={dialogRef} />
            <PopSnackBar ref={snackRef} />
        </Drawer>
    );
}

export default SideBar;