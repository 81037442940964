import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import ContentContainer from './components/ContentContainer/ContentContainer';
import RequestFormTop from './components/RequestFormTop/RequestFormTop';
import ResponseContent from './components/ResponseContent/ResponseContent';
import TopNav from './components/TopNav/TopNav'
import SideBar from './components/SideBar/SideBar';
import React, { useState, useMemo, useRef, useEffect } from "react";
import { Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import defaults from './data/default.json';
import axios from 'axios';
import PopSnackBar from "./components/PopSnackBar/PopSnackBar";
import { useAuth0, } from '@auth0/auth0-react';
import Login from './components/Login/Login';
import CircularProgress from '@mui/material/CircularProgress';
import { uriConfig } from './data/uri';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';

export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

function App(props) {
    const { loadingError } = props;
    const { isLoading, error, loginWithRedirect, getAccessTokenSilently, isAuthenticated } = useAuth0();

    const [requestId, setRequestId] = useState(null);
    const [requestJson, setRequestJson] = useState(null);
    const [response, setResponse] = useState(defaults.response);
    const [responseHeader, setResponseHeader] = useState(defaults.response);
    const [mode, setMode] = useState('light');
    const [token, setToken] = useState(0);
    const [caller, setCaller] = useState(null);
    const [requestButton, setRequestButton] = useState('enabled');
    const [originalRequestJson, setOriginalRequestJson] = useState(null);
    const [saveButton, setSaveButton] = useState();
    const [showOverlay, setShowOverlay] = useState(false);

    const snackRef = useRef();

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                },
            }),
        [mode],
    );

    // this gets called to update property with an object
    const updateQuery = (updatedObject) => {
        setRequestJson(prevState => ({
            ...prevState,
            ...updatedObject
        }));
    }

    // this sets the entire json state
    const updateAllQuery = (data, id = -1, updateOriginal = true) => {
        setRequestJson(data);
        if (updateOriginal === true) {
            setOriginalRequestJson(data)
        }
        if (id != -1) {
            setRequestId(id);
            setResponseHeader(defaults.response);
            setResponse(defaults.response);
        }
    }

    const deleteCheck = (id) => {
        if (requestId == id) {
            setRequestJson(null);
            setOriginalRequestJson(null)
            setResponseHeader(defaults.response);
            setResponse(defaults.response);
        }
    }

    // deals with direct changes to the requestJson for single value property
    const singlePropUpdateFunc = (event) => {
        const key = event.target.name;
        const keys = key.split(".");
        let updateValue = event.target.value;
        if (key === 'storage.file.use_loop') {
            updateValue = parseInt(event.target.value)
        }
        const updateObject = (obj, level, keys, value) => {
            if (level === keys.length - 1) {
                return { ...obj, [keys[level]]: value };
            } else {
                return { ...obj, [keys[level]]: updateObject(obj[keys[level]], level + 1, keys, value) };
            }
        };

        setRequestJson(prevState => {
            return updateObject(prevState, 0, keys, updateValue);
        });
    }

    const getAccessToken = async (tokenCaller) => {
        try {
            const token = await getAccessTokenSilently()
            setToken(token)
            setCaller(tokenCaller)
        } catch (e) {
            snackRef.current.snackBarOpen({ 'message': e })
        }
    }

    const setOverlay = (status) => {
        setShowOverlay(status)
    }

    const getResults = async () => {
        setRequestButton('disabled')
        setOverlay(true)
        axios.get(uriConfig.url.getEnvironment)
            .then(function (response) {
                // handle success
                axios.post(response.data.rest_app_rest_connector_uri + '?code=' + response.data.rest_app_rest_connector_code, requestJson)
                    .then(function (response) {
                        // handle success
                        snackRef.current.snackBarOpen({ 'message': "Data fetched from API!" })
                        // console.log(response.data)
                        setResponse(response.data)
                        setResponseHeader(response.headers)
                        setRequestButton('enabled')
                        setOverlay(false)
                    }).catch(function (error) {
                        // handle error
                        snackRef.current.snackBarOpen({ 'message': error })
                        console.log(error);
                        setOverlay(false);
                        setRequestButton('enabled');
                    });
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
                setOverlay(false);
                setRequestButton('enabled');
            });
    }

    const saveQuery = () => {
        setOverlay(true)
        axios.post(uriConfig.url.saveRequest, { 'requestJson': requestJson }, { params: { 'key': requestId }, headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                setOriginalRequestJson(requestJson)
                snackRef.current.snackBarOpen({ 'message': "Query saved!" })
                console.log(response.data)
                setOverlay(false)
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
                setOverlay(false)
            });
    }

    useEffect(() => {
        getAccessToken('saveQuery')
    }, [caller])

    useEffect(() => {
        const check = JSON.stringify(requestJson) === JSON.stringify(originalRequestJson) ? 0 : 1
        if (check === 0) {
            setSaveButton('disabled')
        }
        else
            setSaveButton('enabled')
    }, [requestJson, originalRequestJson])

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 3, display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' }, "position": "absolute", "right": 0, backgroundColor: "white" }}>
                    <Box style={{ height: '90vh',width: "100vw", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="h6" gutterBottom> This screen size is not supported currently. Please sign in on a larger display. </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <TopNav />
                    <LoadingScreen showOverlay={showOverlay} />
                    {error && (
                        <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 3 }}>
                            <Box style={{ height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="h6" gutterBottom> Authentication error! </Typography>
                            </Box>
                        </Box>
                    )}
                    {!error && isLoading && (
                        <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 3 }}>
                            <Box style={{ height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {loadingError === 'loading' ? <CircularProgress /> : loadingError != null ? String(loadingError) : <CircularProgress />}
                            </Box>
                        </Box>
                    )}
                    {!error && !isLoading && (
                        <Login />
                    )}
                    {isAuthenticated &&
                        <SideBar jsonFunction={updateAllQuery} deleteCheck={deleteCheck} saveButton={saveButton} setOverlay={setOverlay} />
                    }
                    {isAuthenticated ? requestJson === null ?
                        <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 3 }}>
                            <Box style={{ height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="h6" gutterBottom> Pick a query or create a new one to get started </Typography>
                            </Box>
                        </Box>
                        : <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 3 }}>
                            <Toolbar id="back-to-top-anchor" />
                            <ContentContainer>
                                <RequestFormTop
                                    saveQuery={saveQuery}
                                    getResult={getResults}
                                    requestLoop={requestJson.loop}
                                    paramArray={requestJson.parameters}
                                    headerArray={requestJson.headers}
                                    objPropUpdateFunc={updateQuery}
                                    requestAuth={requestJson.authentication}
                                    singlePropUpdateFunc={singlePropUpdateFunc}
                                    queryMethod={requestJson.method}
                                    queryUri={requestJson.uri}
                                    requestButtonState={requestButton}
                                    saveButton={saveButton}
                                    setOverlay={setOverlay}
                                    requestStorage={requestJson.storage}
                                    requestPagination={requestJson.pagination}
                                    jsonFunction={updateAllQuery}
                                />
                            </ContentContainer>
                            <ContentContainer>
                                <ResponseContent
                                    response={response} responseHeader={responseHeader} requestJson={requestJson} jsonFunction={updateAllQuery}
                                />
                            </ContentContainer>
                        </Box> : null
                    }
                    <PopSnackBar ref={snackRef} />
                </Box>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;