import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { CopyBlock, github } from 'react-code-blocks';
import { JsonViewer } from '@textea/json-viewer' 
import JsonTextArea from '../JsonTextArea/JsonTextArea';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ResponseTabs = props => {
    let { response, responseHeader, requestJson, jsonFunction, ...others } = props; // need to change this to const
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', marginTop: '1rem', minHeight: '350px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="response tabs" variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile>
                    <Tab label="Response" {...a11yProps(0)} />
                    <Tab label="Request JSON" {...a11yProps(1)} />
                    <Tab label="🖋️ Edit Request JSON" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <JsonViewer groupArraysAfterLength= '5000' value={response} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <JsonViewer value={requestJson} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <JsonTextArea requestJson={requestJson} jsonFunction={jsonFunction} />
            </TabPanel>            
        </Box>
    );
}


export default ResponseTabs;