import React, { useState, useEffect } from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { DefaultParams } from "../../data/DummyResponse";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const typeOptions = ["url_parameter", "date", "date_list"];

const TypeUrl = (props) => {
    const { jsonArray, level, objPropUpdateFunc, singlePropUpdateFunc, ...other } = props;
    const [inputArr, setInputArr] = useState([]);
    const [inputTrigger, setInputTrigger] = useState(0);


    const buildQueryLoop = (id, value) => {
        let newValues = {};
        const loopSelectedType = value;
        const urlParameter = {
            "id": id,
            "type": "url_parameter",
            "name": "baseid",
            "values": ["random_id"]
        };

        const date = {
            "id": id,
            "type": "date",
            "date": {
                "no_of_days": -1,
                "format": "%Y-%m-%d"
            },
            "name": "date-field"
        };

        const dateList = {
            "id": id,
            "type": "date_list",
            "date_list": {
                "no_of_days": 7,
                "include_today": 0,
                "format": "%Y-%m-%d"
            },
            "name": "date-field"
        };

        switch (loopSelectedType) {
            case "url_parameter":
                newValues = urlParameter;                
                break;
            case "date":
                newValues = date;
                break;
            case "date_list":
                newValues = dateList;
                break;
            default:
                newValues = DefaultParams.loop;
        };

        const updatedState = inputArr.map(obj => obj.id === id ? newValues : obj);
        setInputArr(updatedState);
        setInputTrigger(inputTrigger + 1);
    };


    const addInput = () => {
        setInputArr(s => {
            let newId = 0
            if (Array.isArray(s) && s.length === 1) {
                newId = s[0].id + 1
            }
            else if (Array.isArray(s) && s.length > 1) {
                s.reduce((p, v) => { newId = (p > v ? p.id : v.id) + 1 })
            }

            return [
                ...s,
                {
                    id: newId,
                    type: "url_parameter",
                    name: "property/field name",
                    values: ['value ' + newId]
                }
            ];
        });
        setInputTrigger(inputTrigger + 1)
    }

    // removes input field
    const removeInput = (id) => {
        setInputArr(prevState => prevState.filter(item => item.id !== id));
        setInputTrigger(inputTrigger + 1)
    };

    const inputChangeHandler = (id, key, value) => {
        if (key.includes('.') && key.includes('date') && value !== '-') {
            value = parseInt(value, 10); // ~~value;
        }
        setInputArr(prevItems => {
            const updatedItems = prevItems.map(item => {
                if (item.id === id) {
                    if (key.includes('.')) {
                        const keys = key.split('.');
                        let nestedObj = item;
                        for (let i = 0; i < keys.length - 1; i++) {
                            nestedObj = nestedObj[keys[i]];
                        }
                        nestedObj[keys[keys.length - 1]] = value;
                        return {
                            ...item,
                            [keys[0]]: { ...nestedObj }
                        };
                    } else {
                        return {
                            ...item,
                            [key]: value
                        };
                    }
                }
                return item;
            });
            return updatedItems;
        });
        setInputTrigger(inputTrigger + 1);
    };

    const handleArrayChange = (id, key, value) => {
        const newLoopValues = value.split(',');
        inputChangeHandler(id, key, newLoopValues);
    }

    // update the parent
    const updateParent = () => {
        const propertyPath = 'loop.filters.' + level;
        const event = {
            target: {
                name: propertyPath,
                value: inputArr
            }
        };
        singlePropUpdateFunc(event);
    }

    useEffect(() => {
        if (inputTrigger >= 1) {
            updateParent()
        }
    }, [inputTrigger]);

    useEffect(() => {
        const newJsonArray = jsonArray.map((item, i) => ({ ...item, id: i }));
        setInputArr(newJsonArray)
    }, [jsonArray]);

    return (
        <>
            <Stack direction="row"><Button onClick={() => { addInput(level) }}><AddCircleIcon /></Button></Stack>

            {inputArr.map((item, i) => {
                return (
                    <FormControl
                        noValidate
                        autoComplete="off"
                        id={level}
                        key={i}
                        sx={{
                            display: 'flex !important'
                        }}
                    >
                        <Stack direction="row" columnGap={2} spacing={0} m={0} p={0} justifyContent="space-between" alignItems="flex-start">
                            <InputLabel id="type">Type</InputLabel>
                            <Select
                                size='small'
                                labelId="type"
                                id="type"
                                value={item.type}
                                onChange={e => buildQueryLoop(item.id, e.target.value)}
                                label="Type"
                                name={'loop.filters.' + level + '[0].type'}
                                sx={{
                                    marginBottom: '1.5rem',
                                    width: '150px'
                                }}
                            >
                                {typeOptions.map((option) => {
                                    return <MenuItem key={option} value={option}>{option}</MenuItem>
                                })}
                            </Select>
                            <TextField
                                size='small'
                                required
                                id='name'
                                label='Name'
                                value={item.name}
                                onChange={e => inputChangeHandler(item.id, 'name', e.target.value)}
                                sx={{
                                    marginBottom: '1.5rem',
                                    flexGrow: 1,
                                    minWidth: '20%'
                                }}
                            />
                            {item.type === 'date' ? <><TextField
                                type="number"
                                size='small'
                                required
                                id='noOfDays'
                                label='Number of days'
                                value={item.date.no_of_days}
                                onChange={e => inputChangeHandler(item.id, 'date.no_of_days', e.target.value)}
                                sx={{
                                    marginBottom: '1.5rem',
                                    flexGrow: 1,
                                    minWidth: '20%'
                                }}
                            />
                                <TextField
                                    size='small'
                                    required
                                    id='dateFormat'
                                    label='Date Format'
                                    value={item.date.format}
                                    onChange={e => inputChangeHandler(item.id, 'date.format', e.target.value)}
                                    sx={{
                                        marginBottom: '1.5rem',
                                        flexGrow: 1,
                                        minWidth: '20%'
                                    }}
                                /> </> : null}
                            {item.type === 'date_list' ? <><TextField
                                type="number"
                                size='small'
                                required
                                id='noOfDays'
                                label='Number of days'
                                value={item.date_list.no_of_days}
                                onChange={e => inputChangeHandler(item.id, 'date_list.no_of_days', e.target.value)}
                                sx={{
                                    marginBottom: '1.5rem',
                                    flexGrow: 1,
                                    minWidth: '20%'
                                }}
                            />
                                <TextField
                                    size='small'
                                    required
                                    id='dateFormat'
                                    label='date Format'
                                    value={item.date_list.format}
                                    onChange={e => inputChangeHandler(item.id, 'date_list.format', e.target.value)}
                                    sx={{
                                        marginBottom: '1.5rem',
                                        flexGrow: 1,
                                        minWidth: '20%'
                                    }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={item.date_list.include_today === 1 ? 'checked' : false} onChange={e => inputChangeHandler(item.id, "date_list.include_today", e.target.checked === false ? 0 : 1)} name="include_today" />
                                    }
                                    label="Include today?"
                                /> </> : null}
                            {item.type === 'url_parameter' ? <><TextField
                                size='small'
                                required
                                id='values'
                                label='Values'
                                value={item.values}
                                onChange={e => handleArrayChange(item.id, 'values', e.target.value)}
                                sx={{
                                    marginBottom: '1.5rem',
                                    flexGrow: 1,
                                    minWidth: '30%'
                                }}
                            /> </> : null}
                            <Button id={item.id} onClick={() => removeInput(item.id)} aria-label="delete" size="small" color="error" sx={{ marginLeft: '0 !important', minWidth: '20px', marginTop: '.5rem' }}>
                                <DeleteIcon fontSize="small" />
                            </Button>
                        </Stack>

                    </FormControl>
                );
            })}

        </>
    );
}


export default TypeUrl;