import React, { useState, forwardRef, useImperativeHandle } from "react";
import Snackbar from '@mui/material/Snackbar';

const PopSnackBar = forwardRef((props, ref) =>{
    const {autoHideDuration, message, action, ...other } = props
    const [open, setOpen] = useState(false);
    const [barConfig, setBarConfig] = useState(false);
    const vertical= 'bottom';
    const horizontal= 'center';

    useImperativeHandle(ref, () => ({
        snackBarOpen(config) {
            setBarConfig(config);
            handleOpen();          
        }        
    }));

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message={String(barConfig.message)}
                anchorOrigin={{ vertical, horizontal }} 
            />
        </>
    );
});

export default PopSnackBar;