import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import cat from '../../assets/grumpy-cat.png'

const imageStyle = {
    height: '300px',
    width: 'auto'
}

const Login = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    return (
        !isAuthenticated && (
            <Box style={{ height: '90vh', width: '100%', display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center' }}>
                <img
                    style={imageStyle}
                    src={cat}
                    alt='Cat is not happy about login!'
                    loading="lazy"
                />
                <Typography variant="subtitle1" gutterBottom>OH no! it looks like the <strong>Mr Grumpy</strong> logged you out again!</Typography>
                <Button variant="contained" size="large" onClick={() => loginWithRedirect()}>Login...</Button>
            </Box>
        )
    )
}

export default Login