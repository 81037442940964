import React, { useState, useEffect } from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/system';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const DualInputFields = (props) => {
    const { jsonArray, keyLabel, valueLabel, name, objPropUpdateFunc, ...other } = props;
    const [inputArr, setInputArr] = useState([]);
    const [inputTrigger, setInputTrigger] = useState(0);
    //const [firstReload, setFirstReload] = useState(0);

    // add new input fields
    const addInput = () => {
        setInputArr(s => {
            let newId = 0
            if (Array.isArray(s) && s.length === 1) {
                newId = s[0].id + 1
            }
            else if (Array.isArray(s) && s.length > 1) {
                s.reduce((p, v) => { newId = (p > v ? p.id : v.id) + 1 })
            }
            return [
                ...s,
                {
                    id: newId,
                    key: "key-" + newId,
                    value: "value " + newId
                }
            ];
        });
        setInputTrigger( inputTrigger +1 )
    }

    // removes input field
    const removeInput = (id) => {
        setInputArr(prevState => prevState.filter(item => item.id !== id));
        setInputTrigger( inputTrigger +1 )
    };

    // update headers and params states
    const inputChangeHandler = (name, id, key, value) => {
        setInputArr(prevItems => {
            const updatedItems = prevItems.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        [key]: value
                    };
                }
                return item;
            });
            return updatedItems;
        });
        setInputTrigger( inputTrigger +1 )
    };

    // update the parent
    const updateParent = (name) => {
        const newValues = keyValueCreator(inputArr)
        objPropUpdateFunc({ [name]: newValues });
    }

    // creates objects to be used in the field values
    const objectCreator = (o) => Object.entries(o).map(([key, value], index) => ({
        id: index,
        key,
        value
    }));

    // turns field values to key value pair
    const keyValueCreator = r => r.reduce((obj, header) => {
        obj[header.key] = header.value;
        return obj;
    }, {});

    useEffect(() => {
        if(inputTrigger >= 1){
            updateParent(name)
        }        
    }, [inputTrigger]);

    useEffect(() => {
        let inputJsonArray = {}
        try {
            inputJsonArray = objectCreator(jsonArray)
        } catch {
            inputJsonArray = objectCreator({})
        }
        setInputArr(inputJsonArray)
    }, [jsonArray]);


    return (
        <div>
            <Button onClick={() => { addInput(name) }}><AddCircleIcon /></Button>
            {inputArr.map((item, i) => {
                return (
                    <div key={item.id}>
                        <Stack direction="row" spacing={1} >
                            <FormControl sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }} fullWidth={true} id={name}>
                                <TextField
                                    size='small'
                                    required
                                    id={'key' + item.id}
                                    label={keyLabel}
                                    defaultValue={item.key}
                                    fullWidth={true}
                                    onChange={e => inputChangeHandler(name, item.id, 'key', e.target.value)}
                                    sx={{
                                        minWidth: '100px', margin: '1rem 0.5rem'
                                    }}
                                />
                                <TextField
                                    size='small'
                                    required
                                    id={'value' + item.id}
                                    label={valueLabel}
                                    defaultValue={item.value}
                                    fullWidth={true}
                                    onChange={e => inputChangeHandler(name, item.id, 'value', e.target.value)}
                                    sx={{
                                        minWidth: '100px', margin: '1rem 0.5rem'
                                    }}
                                />
                            </FormControl>
                            <Button id={item.id} onClick={() => removeInput(item.id)} aria-label="delete" size="small" color="error" sx={{ marginLeft: '0 !important', minWidth: '20px' }}>
                                <DeleteIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </div>
                );
            })}
        </div>
    );
}


export default DualInputFields;