import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LinkIcon from '@mui/icons-material/Link';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ListItemText from '@mui/material/ListItemText';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={1} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const MenuAccordion = (props) => {
    const { menuList,
        navigationConfirmation,
        handleRequestCreation,
        openDialog,
        menuEllipsisClick,
        generateUrlRequest,
        deleteRequestConfirmation,
        renameModalConf,
        open,
        anchorEl,
        selectedId,
        handleClose,
        currentId,
        itemSkeletonShow,
        projectId,
        handleContextMenu,
        contextMenu,
        deleteProjectConfirmation,
        renameProjectModalConf,
        ...others } = props;

    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>{(menuList.length > 0 ? menuList.map((item) => (
            <Accordion expanded={expanded === 'panel1' + item.projectId} onChange={handleChange('panel1' + item.projectId)} key={item.projectId} id={item.projectId}>
                <AccordionSummary aria-controls={"panel1" + item.projectId + "d-content"} id={"panel1" + item.projectId + "d-header"} onContextMenu={(e) => handleContextMenu(e, item.projectId)}>
                    <Typography>{item.projectName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List dense={true}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                            <Button sx={{ mr: '10px' }} size="small" onClick={(e) => handleRequestCreation(e, item.projectId)} endIcon={<ControlPointIcon />}>New Request</Button>
                        </Box>
                        <Divider />
                        {item.savedQueries.length == 0 ? <Typography variant="caption" display="block">No requests</Typography> : null}
                        {item.savedQueries.map((singleItem) => (
                            <ListItem key={singleItem.key} disablePadding>
                                <ListItemButton onClick={(e) => { navigationConfirmation(e, singleItem.key) }} selected={selectedId === singleItem.key}>
                                    <ListItemText primary={singleItem.name}
                                        sx={{ my: 0 }}
                                        primaryTypographyProps={{
                                            // fontSize: 14,
                                            fontWeight: 'medium',
                                            letterSpacing: 0,
                                        }} />
                                </ListItemButton>
                                <IconButton
                                    aria-label="more"
                                    id="menu-ellipsis-button"
                                    aria-controls={open ? 'request-list' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => menuEllipsisClick(e, singleItem.key, singleItem.uid)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="request-list"
                                    MenuListProps={{
                                        'aria-labelledby': 'menu-ellipsis-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={() => generateUrlRequest()}>
                                        <LinkIcon sx={{ paddingRight: "5px" }} />
                                        <Typography variant="subtitle2">Direct URL</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => openDialog(renameModalConf)}>
                                        <EditIcon sx={{ paddingRight: "5px" }} />
                                        <Typography variant="subtitle2">Rename</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => deleteRequestConfirmation(currentId)}>
                                        <DeleteForeverIcon sx={{ paddingRight: "5px" }} />
                                        <Typography variant="subtitle2">Delete</Typography>
                                    </MenuItem>
                                </Menu>
                            </ListItem>
                        ))}
                        {itemSkeletonShow === true && item.projectId == projectId ? <Box>
                            <Skeleton width="100%" height={70} />
                        </Box> : null}
                    </List>
                </AccordionDetails>
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem onClick={() => openDialog(renameProjectModalConf)}>
                        <EditIcon sx={{ paddingRight: "5px" }} />
                        <Typography variant="subtitle2">Rename</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => deleteProjectConfirmation(currentId)}>
                        <DeleteForeverIcon sx={{ paddingRight: "5px" }} />
                        <Typography variant="subtitle2">Delete</Typography>
                    </MenuItem>
                </Menu>
            </Accordion>)) : '')}
        </>
    );
}

export default MenuAccordion;