import React, { useState, useEffect } from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import ErrorIcon from '@mui/icons-material/Error';

const OffsetConfig = (props) => {
    const { requestPagination, singlePropUpdateFunc, ...other } = props;
    const [tokenBody, setTokenBody] = useState('');
    const [refresh, setRefresh] = useState(0);
    const [showUpdateButton, setShowUpdateButton] = useState(false);
    const [showUpdateButtonColor, setShowUpdateButtonColor] = useState('success');

    const stylesButton = {
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: '999',
    };

    const GoButton = () => (
        <Button size='small' style={stylesButton} onClick={tokenBodyToJson} color={showUpdateButtonColor}>
            {showUpdateButtonColor === 'success' ? 'Save':'Error'}
            {showUpdateButtonColor === 'success' ? <SaveIcon />:<ErrorIcon/>}
        </Button>
    );

    const tokenBodyToString = () => {
        let jsonString = JSON.stringify(requestPagination.offset.key_value_pair, null, 4);
        setTokenBody(jsonString);
    };

    const softTokenUpdate = (e) => {
        setTokenBody(e.target.value);
        setShowUpdateButton(true);
        try {
            JSON.parse(e.target.value);
            setShowUpdateButtonColor('success')
        } catch (error) {
            setShowUpdateButtonColor('error')
        }
    };

    const tokenBodyToJson = () => {
        if(showUpdateButtonColor === 'success'){
            const jsonObj = JSON.parse(tokenBody);
            const event = {
                target: {
                    name: 'pagination.offset.key_value_pair',
                    value: jsonObj
                }
            };
            singlePropUpdateFunc(event);
            setShowUpdateButton(false);
            setRefresh(refresh === 1 ? 0:1)
        }        
    }

    useEffect(() => {
        tokenBodyToString();
    }, [refresh]);

    return (
        <Stack >
            <FormControl noValidate
                autoComplete="off"
                id='offset'>
                <Stack divider={<Divider orientation="horizontal" flexItem />} direction="row" flexWrap={'wrap'} columnGap={2} spacing={0} m={0} p={0} justifyContent="space-between" alignItems="flex-start">
                    <TextField
                        size='small'
                        required
                        name="pagination.offset.located_in"
                        value={requestPagination.offset.located_in}
                        onChange={singlePropUpdateFunc}
                        id='located_in'
                        label='Located in'
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />
                    <TextField
                        size='small'
                        required
                        name="pagination.offset.append_to"
                        value={requestPagination.offset.append_to}
                        onChange={singlePropUpdateFunc}
                        id='append_to'
                        label='Append to'
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />
                    <TextField
                        size='small'
                        required
                        name="pagination.offset.result_append_to"
                        value={requestPagination.offset.result_append_to}
                        onChange={singlePropUpdateFunc}
                        id='result_append_to'
                        label='Result append to'
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />
                </Stack>
                <Stack>
                    <Stack direction="row" columnGap={2} spacing={0} m={0} p={0}>
                        <TextField
                            size='small'
                            required
                            id="key_value_pair"
                            label="key value pair JSON"
                            multiline
                            rows={3}
                            name="pagination.offset.key_value_pair"
                            value={tokenBody}
                            onChange={(e) => { softTokenUpdate(e) }}
                            onBlur={tokenBodyToJson}
                            InputProps={{ endAdornment: showUpdateButton && (<GoButton />) }}
                            sx={{
                                flexGrow: 1,
                                minWidth: '90%'
                            }}
                        />
                    </Stack>
                </Stack>
            </FormControl>
        </Stack>

    );
}

export default OffsetConfig;