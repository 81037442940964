import React, { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { DefaultParams } from "../../data/DummyResponse";
import StorageConfig from "./StorageConfig";
import SnowflakeStorageConfig from "./SnowflakeStorage";

const storageOptions = ["full_json", "azure_blob","snowflake"];

const StorageSelect = (props) => {
    const { requestStorage, objPropUpdateFunc, singlePropUpdateFunc, ...others } = props;

    // authentication form setter
    const buildQueryStorage = (event) => {
        let newValues = {};
        const storageSelectedType = event.target.value;
        const json = {
            "storage_plan": "single",
            "storage_type": "full_json"
        };
        const azureBlobStorage = {
            "storage_plan": "single",
            "storage_type": "azure_blob",
            "azure_blob": {
                "connection_string": "CONNECTION STRING TO AZURE BLOB",
                "container_name": "NAME OF THE AZURE BLOB CONTAINER"
            },
            "file": {
                "use_loop": 0,
                "file_name": "data_{today}",
                "format": "%Y-%m-%d"
            }
        };
        const snowflakeStorage = {
            "storage_plan": "single",
            "storage_type": "snowflake",
            "snowflake": {
                "tablename": "table",
                "user": "user",
                "password": "password",
                "account": "account.location.vendor",
                "warehouse": "warehouse",
                "database": "database",
                "schema": "schema"

            }
        };

        switch (storageSelectedType) {
            case "full_json":
                newValues = json;
                break;
            case "azure_blob":
                newValues = azureBlobStorage;
                break;
            case "snowflake":
                newValues = snowflakeStorage;
                break;
            default:
                newValues = DefaultParams.storage;
        };
        const reBuild = { storage: newValues }
        objPropUpdateFunc(reBuild);
    };
    
    const renderSwitch = () => {
        switch (requestStorage.storage_type) {
            case "azure_blob":
                return <StorageConfig requestStorage={requestStorage} singlePropUpdateFunc={singlePropUpdateFunc} />;
            case "snowflake":
                return <SnowflakeStorageConfig requestStorage={requestStorage} singlePropUpdateFunc={singlePropUpdateFunc} />;
            case "full_json":
                return null
        }
    }

    return (
        <Stack direction="column" spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
            <FormControl>
                <InputLabel id="method-label">Storage</InputLabel>
                <Select
                    size='small'
                    labelId="method-label"
                    id="method"
                    value={requestStorage.storage_type}
                    label="Storage"
                    name="requestStorage.storage_type"
                    onChange={(e) => buildQueryStorage(e)}
                    inputProps={{ 'data-path': 'method' }}
                    sx={{
                        width: '200px'
                    }}
                >
                    {storageOptions.map((option) => {
                        return <MenuItem key={option} value={option}>{option}</MenuItem>
                    })}
                </Select>
            </FormControl>
            {renderSwitch()}
        </Stack>
    );
}

export default StorageSelect;