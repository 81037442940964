import React, { useState } from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

const BasicAuth = (props) => {
    const { requestAuth, singlePropUpdateFunc, ...other } = props;
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    return (
        <FormControl noValidate
            autoComplete="off"
            id='basicForm'>
            <Stack direction="row" flexWrap={'wrap'} columnGap={2} spacing={0} m={0} p={0} justifyContent="space-between" alignItems="flex-start">
                <TextField
                    size='small'
                    required
                    name="authentication.auth_parameters.username"
                    value={requestAuth.auth_parameters.username}
                    onChange={singlePropUpdateFunc}
                    id='username'
                    label='username'
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth: '40%'
                    }}
                />
                <TextField
                    size='small'
                    required
                    id='password'
                    label='password'
                    name="authentication.auth_parameters.password"
                    value={requestAuth.auth_parameters.password}
                    onChange={singlePropUpdateFunc}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }}
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth: '40%'
                    }}
                />
            </Stack>
        </FormControl>

    );
}


export default BasicAuth;