import * as React from 'react';
import Frame from 'react-frame-component';


const GistComponent = () =>{
    const gistUrl = 'https://gist.github.com/kabirrab/948b1eec4d36c400133b5b4e035026a6.js';
  
    return (
      <div style={{ width: '100%', height: '70vh' }}>
        <Frame
          style={{ width: '100%', height: '100%' }}
          initialContent={`<!DOCTYPE html><html><head></head><body><script src="${gistUrl}"></script></body></html>`}
        />
      </div>
    );
  }
  
  export default GistComponent;