import React, { useState } from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const targetOptions = ["header", "param"];
const ApiKey = (props) => {
    const { requestAuth, singlePropUpdateFunc, ...other } = props;
    return (
        <FormControl noValidate
            autoComplete="off"
            id='apiKeyForm'>
            <Stack direction="row" flexWrap={'wrap'} columnGap={2} spacing={0} m={0} p={0} justifyContent="space-between" alignItems="flex-start">
            <InputLabel id="api-key-target-label">Insert in</InputLabel>
                <Select
                    size='small'
                    labelId="api-key-target-label"
                    id="api-key-target"
                    value={requestAuth.auth_parameters.target}
                    onChange={singlePropUpdateFunc}
                    label="Insert in"
                    name="authentication.auth_parameters.target"
                    sx={{
                        marginBottom: '1.5rem',
                        width: '200px'
                    }}
                >
                    {targetOptions.map((option) => {
                        return <MenuItem key={option} value={option}>{option}</MenuItem>
                    })}
                </Select>
                
                <TextField
                    size='small'
                    required
                    id='key'
                    label='Key'
                    name="authentication.auth_parameters.key"
                    value={requestAuth.auth_parameters.key}
                    onChange={singlePropUpdateFunc}
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth: '30%'
                    }}
                />
                <TextField
                    size='small'
                    required
                    id='value'
                    label='Value'
                    name="authentication.auth_parameters.value"
                    value={requestAuth.auth_parameters.value}
                    onChange={singlePropUpdateFunc}
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth: '30%'
                    }}
                />
            </Stack>
        </FormControl>
    );
}


export default ApiKey;