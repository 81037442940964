import React, { useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DualInputFields from '../DualInput/DualInputFields';
import AuthSelect from '../AuthenticationOptions/AuthSelect';
import LoopTypeSelect from '../LoopOptions/LoopTypeSelect';
import StorageSelect from '../Storage/StorageSelect';
import PaginationSelect from '../Pagination/PaginationSelect'
import NewDialog from "../NewDialog/NewDialog";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { Paper, Typography } from "@mui/material";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import { uriConfig } from "../../data/uri";
import { useAuth0, } from '@auth0/auth0-react';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const RequestTabs = (props) => {
    const { 
            children,
            requestAuth,
            objPropUpdateFunc,
            requestLoop,
            singlePropUpdateFunc,
            headerArray,
            paramArray,
            requestStorage,
            requestPagination,
            jsonFunction,
            ...others 
        } = props;

    const dialogRef = useRef();

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const [autoIncrement, setAutoIncrement] = useState(1);
    const [requestJson, setRequestJson] = useState(null);
    const [value, setValue] = useState(0);
    const [token, setToken] = useState(0);
    const [goButtonState, setGoButtonState] = useState('disabled');
    const [applyButtonState, setApplyButtonState] = useState('disabled');
    const [promptFormState, setPromptFormState] = useState('enabled');
    const [promptResponse, setPromptResponse] = useState('');
    const [error, setError] = useState(0);
    const [validationCheck, setValidationCheck] = useState('');

    const stylesButton = {
        position: 'absolute',
        bottom: '0',
        right: '0',
        zIndex: '999',
    };

    const GoButton = () => (
        <Button disabled={goButtonState === 'disabled' ? true : false} onClick={() => { getAccessToken() }} size='small' style={stylesButton} color='primary'> Generate <SendIcon /></Button>
    );

    const UseButton = () => (
        <Button sx={{ marginRight: "25px" }} disabled={applyButtonState === 'disabled' ? true : false} onClick={() => { applyNewQuery() }} size='small' style={stylesButton} color='primary'> Apply <SubdirectoryArrowRightIcon /></Button>
    );

    const Message = () => {
        if (error !== 0) {
            return <Box><Typography variant="caption" color='error'>some error message</Typography></Box>
        }
    }

    const openDialog = (config) => {
        dialogRef.current.handleOpen(config);
    }

    const updateDialog = (config) => {
        dialogRef.current.updateModal(config)
    }

    const closeDialog = () => {
        dialogRef.current.modalCloseAction()
    }

    const applyNewQuery = () => {
        jsonFunction(requestJson.json_body, -1, false)
        closeDialog()
    }

    const getAccessToken = async () => {
        try {
            setApplyButtonState('disabled')
            setGoButtonState('disabled')
            setPromptFormState('disabled')
            setPromptResponse('I am thinking 🤔. Please wait.....')
            const token = await getAccessTokenSilently()
            setToken(token)
            setAutoIncrement(autoIncrement + 1)
            // setCaller(tokenCaller)
        } catch (e) {
            console.log("error with access token - ", e)
            // snackRef.current.snackBarOpen({ 'message': e })
        }
    }

    const sendQuestion = async () => {
        if (isAuthenticated) {
            const question = document.getElementById('chatGptPrompt').value;
            axios.get(uriConfig.url.getEnvironment)
            .then(function (response) {
                // handle success
                axios.post(response.data.question_uri + '?code=' + response.data.rest_app_rest_connector_code, { 'question': question })
                .then(function (response) {
                    // handle success
                    console.log(response.data)
                    setRequestJson(response.data)
                    setPromptResponse('I am thinking 🤔. Please wait..... \nI am now validating the answer.....')
                }).catch(function (error) {
                    // handle error
                    console.log(error);
                    setPromptResponse('Sorry, i tried running before learning to walk and broke my legs... perhaps you should try again and let me walk this time!')
                    setPromptFormState('enabled')
                    setGoButtonState('enabled')
                });
            }).catch(function (error) {
                // handle error
                console.log(error);
                setPromptResponse('Sorry, it seems I have fallen into a hole.... perhaps you should try again!')
                setPromptFormState('enabled')
                setGoButtonState('enabled')
            }); 
        }
    }
    
    const validatePayload = async () => {
        axios.get(uriConfig.url.getEnvironment)
            .then(function (response) {
                // handle success
                axios.post(response.data.rest_app_rest_connector_uri + '?code=' + response.data.rest_app_rest_connector_code, requestJson.json_body)
                    .then(function (response) {
                        // handle success
                        console.log(response.data)
                        setValidationCheck(response.data)
                        setPromptFormState('enabled')
                        setGoButtonState('enabled')
                    }).catch(function (error) {
                        // handle error                        
                        let errorMessage = "I am sorry, validation failed. Try providing more information. Here is the validation error - \n\n";
                        errorMessage += JSON.stringify(error.response.data)
                        console.log(error);
                        setPromptResponse(errorMessage)
                        setPromptFormState('enabled')
                        setGoButtonState('enabled')
                    });
            }).catch(function (error) {
                // handle error
                console.log(error);
                setPromptResponse('Sorry I did not understand the question. Can you provide more details please?')
                setPromptFormState('enabled')
                setGoButtonState('enabled')
            });
    }

    const checkDefaultProperties = (json) => {
        // Check if 'storage' property is missing
        if (!json.hasOwnProperty('storage')) {
            json.storage = {
                "storage_plan": "single",
                "storage_type": "full_json"
            };
        }

        // Check if 'pagination' property is missing
        if (!json.hasOwnProperty('pagination')) {
            json.pagination = {                
                "pagination_type": "none"
            };
        }

        // Check if 'loop' property is missing
        if (!json.hasOwnProperty('loop')) {
            json.loop = {
                "loop_type": "none"
            };
        }

        // Check if 'authentication' property is missing
        if (!json.hasOwnProperty('authentication')) {
            json.authentication = {
                "type": "none"
            };
        }

        // Check if 'method' property is missing
        if (!json.hasOwnProperty('method')) {
            json.method = "GET";
        }

        return json;
    };

    const inputLengthCheck = (event) => {
        const inputLength = event.target.value;
        if (inputLength.length > 29) {
            setGoButtonState('enabled')
        } else {
            setGoButtonState('disabled')
        }
    };

    const chatModalConf = {
        title: 'Co-Pilot (Experimental)',
        maxWidth: 'xl',
        components: <Stack direction="column" spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
            <Stack direction="row" justifyContent="center"
                alignItems="center" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                <TextField
                    sx={{ "margin": "10px 0 10px 0", "width": "45%" }}
                    id="chatGptPrompt"
                    label="Co-Pilot prompt"
                    multiline
                    rows={6}
                    placeholder="Which API endpoint would you like to connect? Please provide as detailed explanation as you can. (Minimum of 30 characters required)"
                    onChange={e => inputLengthCheck(e)}
                    disabled={promptFormState === 'disabled' ? true : false}
                    InputProps={{ endAdornment: <GoButton /> }}
                />
                <TextField
                    sx={{ "margin": "10px 0 10px 0", "width": "45%" }}
                    id="chatGptAnswer"
                    label="Co-Pilot response"
                    multiline
                    rows={6}
                    value={promptResponse}
                    InputProps={{ endAdornment: <UseButton /> }}
                />
            </Stack>
            {Message()}
            <Box >
                <Typography variant="caption">
                    Co-Pilot is taking its baby steps and learning to walk. Please be gentle with our pilot!
                    <a href="https://google.com" target="_blank"> Here</a> are some examples of how to use the
                    "Co-Pilot" prompt to build the API query.
                </Typography>
            </Box>
        </Stack>,
        cancelButton: 1,
        buttons: {
            bottom: [
            ]
        }
    };

    const openCoPilot = () => {
        openDialog(chatModalConf);
        setPromptResponse('');
        setAutoIncrement(1);
        setRequestJson(null);
        setGoButtonState('disabled');
        setApplyButtonState('disabled');
        setPromptFormState('enabled');
        setError(0);
        setValidationCheck('');
    }

    const ButtonInTabs = ({ onClick, children }) => {
        return <Button sx={{ "position": "absolute", "right": 0, "top": 5 }} variant='outlined' onClick={onClick} children={children} />;
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (autoIncrement > 1) {
            sendQuestion()
        }
    }, [autoIncrement]);

    useEffect(() => {
        updateDialog(chatModalConf)
    }, [goButtonState, error, promptResponse]);

    useEffect(() => {
        if (validationCheck && Object.keys(validationCheck).length !== 0) {
            if (validationCheck.outcome === 'valid') {
                let jsonPayload = requestJson.json_body;
                delete jsonPayload.validate;
                jsonPayload = checkDefaultProperties(jsonPayload);
                const validPayload = JSON.stringify(jsonPayload, null, 4); // Indented 4 spaces 
                setPromptFormState('enabled')
                setGoButtonState('enabled')
                setPromptResponse(validPayload)
                setApplyButtonState('enabled')
            }
        }
    }, [validationCheck]);

    useEffect(() => {
        if (requestJson && Object.keys(requestJson).length !== 0) {
            if (requestJson.json_body && requestJson.json_body.error) {
                console.log('Error:', requestJson.json_body.error);
                setPromptFormState('enabled')
                setGoButtonState('enabled')
                setPromptResponse('Sorry I did not understand the question. Can you provide more details please?')
            }
            else {
                validatePayload()
            }
        }
    }, [requestJson]);

    return (
        <Box sx={{ width: '100%', marginTop: '1rem', height: '350px', overflowY: "auto" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="request tabs" variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile>
                    <Tab label="Authentication" {...a11yProps(0)} />
                    <Tab label="Headers" {...a11yProps(1)} />
                    <Tab label="Parameters" {...a11yProps(2)} />
                    <Tab label="Loop" {...a11yProps(3)} />
                    <Tab label="Pagination" {...a11yProps(4)} />
                    <Tab label="Storage" {...a11yProps(5)} />
                    {/* <Tab label="Schedules" {...a11yProps(6)} disabled /> */}
                    <ButtonInTabs onClick={() => { openCoPilot() }} variant='contained' size='small' color='primary'>Co-Pilot</ButtonInTabs>
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <AuthSelect requestAuth={requestAuth} objPropUpdateFunc={objPropUpdateFunc} singlePropUpdateFunc={singlePropUpdateFunc} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DualInputFields
                    keyLabel='Key'
                    valueLabel='Value'
                    name='headers'
                    jsonArray={headerArray}
                    objPropUpdateFunc={objPropUpdateFunc}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DualInputFields
                    keyLabel='Parameter Key'
                    valueLabel='Parameter Value'
                    name='parameters'
                    jsonArray={paramArray}
                    objPropUpdateFunc={objPropUpdateFunc}
                />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <LoopTypeSelect requestLoop={requestLoop} objPropUpdateFunc={objPropUpdateFunc} singlePropUpdateFunc={singlePropUpdateFunc} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <PaginationSelect requestPagination={requestPagination} objPropUpdateFunc={objPropUpdateFunc} singlePropUpdateFunc={singlePropUpdateFunc} />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <StorageSelect requestStorage={requestStorage} objPropUpdateFunc={objPropUpdateFunc} singlePropUpdateFunc={singlePropUpdateFunc} />
            </TabPanel>
           {/*  <TabPanel value={value} index={6}>
                Schedules
            </TabPanel> */}
            <NewDialog ref={dialogRef} />
        </Box>
    );
}

export default RequestTabs;