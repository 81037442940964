import React, { useState } from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const SnowflakeStorageConfig = (props) => {
    const { requestStorage, singlePropUpdateFunc, ...other } = props;
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeLoop = (e) => {
        let storageTypeEvent = '';
        const changeValue = parseInt(e.target.value)
        if (changeValue === 1) {
            storageTypeEvent = {
                'target': { 'name': 'storage.storage_plan', 'value': 'loop' }
            }
        }
        else {
            storageTypeEvent = {
                'target': { 'name': 'storage.storage_plan', 'value': 'single' }
            }
        }

        singlePropUpdateFunc(storageTypeEvent)
    }

    return (
        <Stack >
            <Stack my={2} direction="row" flexWrap={'wrap'} columnGap={2} spacing={0} p={0} justifyContent="space-between" alignItems="flex-start">
                <FormControl>
                    <FormControlLabel
                        control={<Switch
                            name='storage.storage_plan_flag'
                            value={requestStorage.storage_plan === 'single' ? 1 : 0}
                            size='small'
                            checked={requestStorage.storage_plan === 'single' ? false : true}
                            onChange={handleChangeLoop}
                            inputProps={{ 'aria-label': 'controlled' }} />}
                        labelPlacement="top"
                        value='url'
                        label="Row per loop?"
                        sx={{
                            width: '110px',
                            alignItems: 'start'
                        }}
                    />
                </FormControl>
            </Stack>
            <FormControl noValidate
                autoComplete="off"
                id='basicForm'>
                <Stack divider={<Divider orientation="horizontal" flexItem />} direction="row" flexWrap={'wrap'} columnGap={2} spacing={0} m={0} p={0} justifyContent="space-between" alignItems="flex-start">
                    <TextField
                        size='small'
                        required
                        name="storage.snowflake.account"
                        value={requestStorage.snowflake.account}
                        onChange={singlePropUpdateFunc}
                        id='account'
                        label='Account'
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />
                    <TextField
                        size='small'
                        required
                        name="storage.snowflake.user"
                        value={requestStorage.snowflake.user}
                        onChange={singlePropUpdateFunc}
                        id='user'
                        label='User'
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />
                    <TextField
                        size='small'
                        required
                        id='Password'
                        label='Password'
                        name="storage.snowflake.password"
                        value={requestStorage.snowflake.password}
                        onChange={singlePropUpdateFunc}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />
                    <TextField
                        size='small'
                        required
                        name="storage.snowflake.warehouse"
                        value={requestStorage.snowflake.warehouse}
                        onChange={singlePropUpdateFunc}
                        id='warehouse'
                        label='Warehouse'
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />
                    <TextField
                        size='small'
                        required
                        name="storage.snowflake.database"
                        value={requestStorage.snowflake.database}
                        onChange={singlePropUpdateFunc}
                        id='database'
                        label='Database'
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />
                    <TextField
                        size='small'
                        required
                        name="storage.snowflake.schema"
                        value={requestStorage.snowflake.schema}
                        onChange={singlePropUpdateFunc}
                        id='schema'
                        label='Schema'
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />
                    <TextField
                        size='small'
                        required
                        name="storage.snowflake.tablename"
                        value={requestStorage.snowflake.tablename}
                        onChange={singlePropUpdateFunc}
                        id='tablename'
                        label='Table Name'
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />

                </Stack>
            </FormControl>
        </Stack>

    );
}


export default SnowflakeStorageConfig;