import React, { useState } from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

const methodOptions = ["GET", "POST", "PUT"];

const BasicAuthExtended = (props) => {
    const { requestAuth, singlePropUpdateFunc, ...other } = props;
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    return (
        <FormControl
            noValidate
            autoComplete="off"
            id='basicExtForm'>
            <Stack direction="row" columnGap={2} spacing={0} m={0} p={0}>
                <InputLabel id="auth-query-method-label">Method</InputLabel>
                <Select
                    size='small'
                    labelId="auth-query-method-label"
                    id="auth-query-method"
                    value={requestAuth.auth_parameters.token_method}
                    onChange={singlePropUpdateFunc}
                    label="Method"
                    name="authentication.auth_parameters.token_method"
                    sx={{
                        marginBottom: '1.5rem',
                        width: '100px'
                    }}
                >
                    {methodOptions.map((option) => {
                        return <MenuItem key={option} value={option}>{option}</MenuItem>
                    })}
                </Select>
                <TextField
                    fullWidth={true}
                    size='small'
                    required
                    id='token_url'
                    label='Login Url'
                    name="authentication.auth_parameters.token_url"
                    value={requestAuth.auth_parameters.token_url}
                    onChange={singlePropUpdateFunc}
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1
                    }}
                />
            </Stack>
            <Stack direction="row" flexWrap={'wrap'} columnGap={2} spacing={0} m={0} p={0} justifyContent="space-between"  alignItems="flex-start">
                <TextField
                    size='small'
                    required
                    name="authentication.auth_parameters.username"
                    value={requestAuth.auth_parameters.username}
                    onChange={singlePropUpdateFunc}
                    id='username'
                    label='username'
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth:'33%'
                    }}                    
                />
                <TextField
                    size='small'
                    required
                    id='password'
                    label='password'
                    name="authentication.auth_parameters.password"
                    value={requestAuth.auth_parameters.password}
                    onChange={singlePropUpdateFunc}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }}
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth:'33%'
                    }} 
                />
                <TextField
                    size='small'
                    required
                    id='token_location'
                    label='Token Location'
                    name="authentication.auth_parameters.token_location"
                    value={requestAuth.auth_parameters.token_location}
                    onChange={singlePropUpdateFunc}
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth:'33%'
                    }} 
                />
                <TextField
                    size='small'
                    required
                    id='custom_flow'
                    label='custom flow'
                    name="authentication.auth_parameters.custom_flow"
                    value={requestAuth.auth_parameters.custom_flow}
                    onChange={singlePropUpdateFunc}
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth:'33%'
                    }} 
                />
            </Stack>

        </FormControl>

    );
}


export default BasicAuthExtended;