import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/system';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import RequestTabs from '../RequestTabs/RequestTabs';
import IconButton from '@mui/material/IconButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const RequestFormTop = (props) => {
    const {
            children, 
            getResult, 
            saveQuery, 
            queryMethod, 
            singlePropUpdateFunc, 
            queryUri, 
            requestAuth, 
            objPropUpdateFunc, 
            requestLoop, 
            headerArray, 
            paramArray, 
            requestButtonState, 
            saveButton, 
            requestStorage, 
            jsonFunction, 
            experimental,
            requestPagination,
            ...others 
        } = props;
    const GoButton = () => {
        return(
        <IconButton disabled={requestButtonState==='disabled' ? true:false} onClick={()=>{getResult()}}>
          <PlayCircleOutlineIcon />
        </IconButton>
        )
        }
    
    return (
        <>
        <Stack direction="row" spacing={1}>
            <FormControl sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }} fullWidth={true}>
                <InputLabel id="query-method-label">Method</InputLabel>
                <Select
                    size='small'
                    labelId="query-method-label"
                    id="query-method"
                    value={queryMethod}
                    label="method"
                    name="method"
                    onChange={singlePropUpdateFunc}
                    inputProps={{'data-path': 'method'}}
                    sx={{
                        marginRight: '10px',
                        minWidth: '100px'
                    }}
                >
                    <MenuItem value={"GET"}>GET</MenuItem>
                    <MenuItem value={"POST"}>POST</MenuItem>
                    <MenuItem value={"PUT"}>PUT</MenuItem>
                </Select>
                <TextField
                    size='small'
                    required
                    id="queryUri"
                    label="Url"
                    name="uri"
                    value={queryUri}
                    onChange={singlePropUpdateFunc}
                    fullWidth={true}
                    InputProps={{'data-path': 'uri', endAdornment: <GoButton />}}
                    sx={{
                        minWidth: '100px'
                    }}                
                />
            </FormControl>
            {/* <Button size='small' variant="outlined">GO</Button> */}
            <Button disabled={saveButton==='disabled' ? true:false} size='small' variant="contained"  onClick={()=>{saveQuery()}}><SaveIcon/></Button>
        </Stack>
        <RequestTabs
            singlePropUpdateFunc={singlePropUpdateFunc}
            requestLoop={requestLoop}
            requestAuth={requestAuth} 
            objPropUpdateFunc={objPropUpdateFunc} 
            headerArray={headerArray} 
            paramArray={paramArray}
            requestStorage={requestStorage}
            requestPagination={requestPagination}
            jsonFunction={jsonFunction}
        />
        </>
    );
}

export default RequestFormTop;