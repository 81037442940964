import React, { useState, useEffect } from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import ErrorIcon from '@mui/icons-material/Error';


const BearerToken = (props) => {
    const { requestAuth, singlePropUpdateFunc, ...other } = props;
    const [tokenBody, setTokenBody] = useState('');
    const [showUpdateButton, setShowUpdateButton] = useState(false);
    const [showUpdateButtonColor, setShowUpdateButtonColor] = useState('success');
    const [refresh, setRefresh] = useState(0);

    const stylesButton = {
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: '999',
    };

    const GoButton = () => (
        <Button size='small' style={stylesButton} onClick={tokenBodyToJson} color={showUpdateButtonColor}>
            {showUpdateButtonColor === 'success' ? 'Save':'Error'}
            {showUpdateButtonColor === 'success' ? <SaveIcon />:<ErrorIcon/>}
        </Button>
    );

    const tokenBodyToString = () => {
        let jsonString = JSON.stringify(requestAuth.auth_parameters.token_body, null, 4);
        setTokenBody(jsonString);
    };

    const softTokenUpdate = (e) => {
        setTokenBody(e.target.value);
        setShowUpdateButton(true);
        try {
            JSON.parse(e.target.value);
            setShowUpdateButtonColor('success')
        } catch (error) {
            setShowUpdateButtonColor('error')
        }
    };

    const tokenBodyToJson = () => {
        if(showUpdateButtonColor === 'success'){
            const jsonObj = JSON.parse(tokenBody);
            const event = {
                target: {
                    name: 'authentication.auth_parameters.token_body',
                    value: jsonObj
                }
            };
            singlePropUpdateFunc(event);
            setShowUpdateButton(false);
            setRefresh(refresh === 1 ? 0:1)
        }        
    }

    useEffect(() => {
        tokenBodyToString();
    }, [refresh]);

    useEffect(() => {
        tokenBodyToString();
    }, [requestAuth]);

    return (
        <FormControl
            noValidate
            autoComplete="off"
            id='basicExtForm'>
            <Stack direction="row" columnGap={2} spacing={0} m={0} p={0}>
                <TextField
                    size='small'
                    required
                    id='token_url'
                    label='Token Url'
                    name="authentication.auth_parameters.token_url"
                    value={requestAuth.auth_parameters.token_url}
                    onChange={singlePropUpdateFunc}
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth: '40%'
                    }}
                />
                <TextField
                    size='small'
                    required
                    id='token_location'
                    label='Token Location'
                    name="authentication.auth_parameters.token_location"
                    value={requestAuth.auth_parameters.token_location}
                    onChange={singlePropUpdateFunc}
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth: '40%'
                    }}
                />
            </Stack>
            <Stack direction="row" columnGap={2} spacing={0} m={0} p={0}>
                <TextField                    
                    size='small'
                    required
                    id="token_body"
                    label="Token Body JSON"
                    multiline
                    rows={5}
                    name="authentication.auth_parameters.token_body"
                    value={tokenBody}
                    onChange={(e) => { softTokenUpdate(e) }}
                    onBlur={tokenBodyToJson}
                    InputProps={{ endAdornment: showUpdateButton && (<GoButton />) }}
                    sx={{
                        flexGrow: 1,
                        minWidth: '90%'
                    }}
                />
            </Stack>
        </FormControl>

    );
}


export default BearerToken;