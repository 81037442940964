import React, { useState, useEffect } from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SyncIcon from '@mui/icons-material/Sync';
import ErrorIcon from '@mui/icons-material/Error';


const JsonTextArea = (props) => {
    const { requestJson, jsonFunction, ...other } = props;
    let newRequestJson = requestJson;
    const [tokenBody, setTokenBody] = useState('');
    const [showUpdateButton, setShowUpdateButton] = useState(false);
    const [showUpdateButtonColor, setShowUpdateButtonColor] = useState('success');
    const [refresh, setRefresh] = useState(0);

    const stylesButton = {
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: '999',
    };

    const GoButton = () => (
        <Button size='small' style={stylesButton} onClick={tokenBodyToJson} color={showUpdateButtonColor}>
            {showUpdateButtonColor === 'success' ? 'Update':'Error'}
            {showUpdateButtonColor === 'success' ? <SyncIcon />:<ErrorIcon/>}
        </Button>
    );

    const tokenBodyToString = () => {
        let jsonString = JSON.stringify(newRequestJson, null, 4);
        setTokenBody(jsonString);
    };

    const softTokenUpdate = (e) => {
        setTokenBody(e.target.value);
        setShowUpdateButton(true);
        try {
            JSON.parse(e.target.value);
            setShowUpdateButtonColor('success')
        } catch (error) {
            setShowUpdateButtonColor('error')
        }
    };

    const tokenBodyToJson = () => {
        if(showUpdateButtonColor === 'success'){
            const jsonObj = JSON.parse(tokenBody);
            jsonFunction(jsonObj,-1,false);
            setShowUpdateButton(false);
            setRefresh(refresh === 1 ? 0:1)
        }        
    }

    useEffect(() => {
        tokenBodyToString();
    }, [refresh]);

    useEffect(() => {
        newRequestJson = requestJson;
        tokenBodyToString();
    }, [requestJson]);

    return (
        <Box component="section" sx={{ width:"100%", p: 2, border: '1px dashed grey' }}>
            <FormControl
                noValidate
                autoComplete="off"
                id='basicExtForm'
                fullWidth={true}>
                <TextField 
                    error = {showUpdateButtonColor === 'error' ? true:false}
                    fullWidth={true}                   
                    size='small'
                    required
                    id="token_body"
                    label="Token Body JSON"
                    multiline
                    //rows={5}
                    //name="authentication.auth_parameters.token_body"
                    value={tokenBody}
                    onChange={(e) => { softTokenUpdate(e) }}
                    onBlur={tokenBodyToJson}
                    InputProps={{ endAdornment: showUpdateButton && (<GoButton />) }}
                    sx={{
                        flexGrow:1, 
                        minWidth: '100%'
                    }}
                />
            
        </FormControl>
        </Box>
        

    );
}


export default JsonTextArea;