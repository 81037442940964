import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import BasicAuth from "./Basic";
import BasicAuthExtended from "./BasicExtended";
import ApiKey from "./APIKey";
import BearerToken from "./BearerToken";
import { DefaultParams } from "../../data/DummyResponse";

const authOptions = ["none", "basic", "basic_extended", "api_key", "bearer"];

const AuthSelect = (props) => {
    const {requestAuth, objPropUpdateFunc, singlePropUpdateFunc, ...others} = props;
    // authentication form setter
    const buildQueryAuth = (event) => {
        let newValues = {};
        const authSelectedType = event.target.value;
        const basic = {
            type: "basic",
            auth_parameters: {
                username: "USERNAME",
                password: "PASSWORD"
            }
        };
        const api_key = {
            type: "api_key",
            auth_parameters: {
                target: "header",
                key: "api_key_header_name",
                value: "your_api_key"
            }
        };
        const basic_extended = {
            type: "basic_extended",
            auth_parameters: {
                token_method: "GET",
                username: "john@example.com",
                password: "abc123",
                token_url: "https://mysite.com/account/login",
                token_location: "['token_location']",
                custom_flow: "custom_flow"
            }
        };
        const bearer = {
            type: "bearer",
            auth_parameters: {
                token_url: "https://myapi.com/api/login",
                token_location: "['access_token']",
                token_body: {
                    "grant_type": "access_token",
                    "client_id": "client_id",
                    "client_secret": "client_secret"
                }
            }
        };

        switch (authSelectedType) {
            case "basic":
                newValues = basic;
                break;
            case "basic_extended":
                newValues = basic_extended;
                break;
            case "api_key":
                newValues = api_key;
                break;
            case "bearer":
                newValues = bearer;
                break;
            default:
                newValues = DefaultParams.authentication;
        };
        const reBuild = {authentication: newValues}        
        objPropUpdateFunc(reBuild);        
    };

    const renderSwitch = () => {
        switch (requestAuth.type) {
            case "basic":
                return <BasicAuth requestAuth={requestAuth} singlePropUpdateFunc={singlePropUpdateFunc} />;
            case "basic_extended":
                return <BasicAuthExtended requestAuth={requestAuth} singlePropUpdateFunc={singlePropUpdateFunc} />
            case "api_key":
                return <ApiKey requestAuth={requestAuth} singlePropUpdateFunc={singlePropUpdateFunc} />
            case "bearer":
                return <BearerToken requestAuth={requestAuth} singlePropUpdateFunc={singlePropUpdateFunc} />
        }
    }

    return (
        <Stack direction="column" spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
            <FormControl>
                <InputLabel id="method-label">Auth</InputLabel>
                <Select
                    size='small'
                    labelId="method-label"
                    id="method"
                    value={requestAuth.type}
                    label="Auth"
                    name="authentication.type"
                    onChange={(e)=>buildQueryAuth(e)}
                    inputProps={{ 'data-path': 'method' }}
                    sx={{
                        width: '200px'
                    }}
                >
                    {authOptions.map((option) => {
                        return <MenuItem key={option} value={option}>{option}</MenuItem>
                    })}
                </Select>
            </FormControl>
            {renderSwitch()}
        </Stack>
    );
}

export default AuthSelect;