import React, { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { DefaultParams } from "../../data/DummyResponse";
import OffsetConfig from "./OffsetConfig";

const paginationOptions = ["none","offset"];

const PaginationSelect = (props) => {
    const { requestPagination, objPropUpdateFunc, singlePropUpdateFunc, ...others } = props;

    // pagination form setter
    const buildQueryPagination = (event) => {
        let newValues = {};
        const paginationSelectedType = event.target.value;
        const none = {
            "pagination_type": "none"
        };
        
        const offset = {
            "pagination_type": "offset",
            "offset": {
                "located_in": "body",
                "append_to": "params",
                "key_value_pair":{
                    "KEY NAME TO BE PASSED ON NEXT QUERY":"['OFFSET VALUE KEY NAME']"
                },               
                "result_append_to":"['NAME OF THE KEY WHERE TO APPEND THE RESULTS TO']"
                }
            }

        switch (paginationSelectedType) {
            case "none":
                newValues = none;
                break;
            case "offset":
                newValues = offset;
                break;            
            default:
                newValues = DefaultParams.pagination;
        };
        const reBuild = { pagination: newValues }
        objPropUpdateFunc(reBuild);
    };
    
    const renderSwitch = () => {
        switch (requestPagination.pagination_type) {
            case "offset":
                return <OffsetConfig requestPagination={requestPagination} singlePropUpdateFunc={singlePropUpdateFunc} />;
            case "none":
                return null
        }
    }

    return (
        <Stack direction="column" spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
            <FormControl>
                <InputLabel id="method-label">Pagination type</InputLabel>
                <Select
                    size='small'
                    labelId="method-label"
                    id="method"
                    value={requestPagination.pagination_type}
                    label="Pagination type"
                    name="requestPagination.pagination_type"
                    onChange={(e) => buildQueryPagination(e)}
                    inputProps={{ 'data-path': 'method' }}
                    sx={{
                        width: '200px'
                    }}
                >
                    {paginationOptions.map((option) => {
                        return <MenuItem key={option} value={option}>{option}</MenuItem>
                    })}
                </Select>
            </FormControl>
           {renderSwitch()}
        </Stack>
    );
}

export default PaginationSelect;