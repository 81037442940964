import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import placeholderJson from '../../data/data.json';
import PopSnackBar from "../PopSnackBar/PopSnackBar";
import { uriConfig } from "../../data/uri";
import { useAuth0, } from '@auth0/auth0-react';
import { CodeBlock, CopyBlock, dracula } from 'react-code-blocks';
import CustomizedAccordions from "./MenuAccordion";

const ProjectMenu = forwardRef((props, ref) => {
    const { jsonFunction, openDialog, requestDialogueClose, updateDialog, deleteCheck, saveButton, setOverlay, selectedRequestId, ...other } = props;

    const [listState, setListState] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [contextMenu, setContextMenu] = useState(null);
    const [currentId, setCurrentId] = useState(null);
    const [currentProjectId, setCurrentProjectId] = useState(null);
    const [renameProjectNameErr, setRenameProjectNameErr] = useState(false);
    const [currentUid, setCurrentUid] = useState(null);
    const [projectId, setProjectId] = useState(null);
    const [itemSkeletonShow, setItemSkeletonShow] = useState(false);
    const [requestNameErr, setRequestNameErr] = useState(false);
    const [renameNameErr, setRenameNameErr] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [token, setToken] = useState(0);
    const [caller, setCaller] = useState(null);
    const [dataFetched, setDataFetched] = useState(null);

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const snackRef = useRef();

    const open = Boolean(anchorEl);

    useImperativeHandle(ref, () => ({
        updateList() {
            getList();
        }
    }));

    const getAccessToken = async (tokenCaller) => {
        try {
            const token = await getAccessTokenSilently()
            setToken(token)
            setCaller(tokenCaller)
        } catch (e) {
            snackRef.current.snackBarOpen({ 'message': e })
        }
    }

    const getList = async () => {
        if (isAuthenticated) {
            axios.get(uriConfig.url.listAll, { headers: { 'auth-authorization': 'Bearer ' + token } })
                .then(function (response) {
                    // handle success
                    setListState(response.data)
                    setDataFetched(1)
                    //console.log(response.data)
                    snackRef.current.snackBarOpen({ 'message': "All request loaded!" })
                    if (projectId != null) {
                        setProjectId(null);
                        setItemSkeletonShow(false);
                    }
                }).catch(function (error) {
                    // handle error
                    snackRef.current.snackBarOpen({ 'message': error })
                    console.log(error);
                });
        }
    }

    const navigationConfirmation = (event, id) => {
        if (saveButton != 'disabled') {
            const requestModalConf = {
                title: 'Discard unsaved changes?',
                context: 'You have some unsaved changes. Press "Continue" to discard and navigate away or press "Cancel" to go back and save the changes.',
                components: <input aria-invalid="false" id="requestId" type="hidden" value={id} />,
                cancelButton: 1,
                buttons: {
                    bottom: [
                        {
                            label: 'Continue',
                            color: "error",
                            onClick: getJsonField
                        }
                    ]
                }
            };
            openDialog(requestModalConf)
        }
        else {
            getJsonField(null, id)
        }
    }

    const getJsonField = (event, id = -1) => {
        setOverlay(true)
        if (id === -1) {
            id = document.getElementById('requestId').value;
        }
        setSelectedId(id);
        axios.get(uriConfig.url.getItem, { params: { key: id }, headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                snackRef.current.snackBarOpen({ 'message': "Request " + id + " loaded!" })
                console.log(response.data)
                jsonFunction(response.data, id)
                requestDialogueClose();
                setOverlay(false)
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
                requestDialogueClose();
                setOverlay(false)
            });
    }

    const deleteProjectConfirmation = (id) => {
        const requestModalDeleteConf = {
            title: 'Are you sure you wish to delete project?',
            context: 'Press delete to confirm project. Be aware this will delete all associated requests within the project!',
            components: <input aria-invalid="false" id="projectDeleteId" type="hidden" value={id} />,
            cancelButton: 1,
            buttons: {
                bottom: [
                    {
                        label: 'Delete Project',
                        color: "error",
                        onClick: deleteProject
                    }
                ]
            }
        };
        openDialog(requestModalDeleteConf)
    }

    const deleteProject = () => {
        const id = document.getElementById('projectDeleteId').value;
        console.log("delete project - ", id);
        axios.post(uriConfig.url.removeProject, { 'key': id }, { headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                console.log(response.data)
                getList();
                handleClose();
                requestDialogueClose();
            })
    }

    const deleteRequestConfirmation = (id) => {
        const requestModalDeleteConf = {
            title: 'Are you sure you wish to delete?',
            context: 'Press delete to confirm',
            components: <input aria-invalid="false" id="requestDeleteId" type="hidden" value={id} />,
            cancelButton: 1,
            buttons: {
                bottom: [
                    {
                        label: 'Delete',
                        color: "error",
                        onClick: deleteRequest
                    }
                ]
            }
        };
        openDialog(requestModalDeleteConf)
    }

    const deleteRequest = () => {
        const id = document.getElementById('requestDeleteId').value;
        // console.log("delete request - ", id);
        axios.post(uriConfig.url.removeItem, { 'key': id }, { headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                snackRef.current.snackBarOpen({ 'message': "Request " + id + " deleted!" })
                console.log(response.data)
                getList();
                deleteCheck(id);
                handleClose();
                requestDialogueClose();
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
                requestDialogueClose();
            });
    }

    const renameRequest = () => {
        setRenameNameErr(true)
        console.log("renaming request - ", currentId);
        const requestName = document.getElementById('requestName').value;
        axios.post(uriConfig.url.renameItem, { 'name': requestName }, { params: { 'key': currentId }, headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                snackRef.current.snackBarOpen({ 'message': "Request renamed to - '" + requestName + "'!" })
                console.log(response.data)
                getList();
                handleClose();
                requestDialogueClose();
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
                requestDialogueClose();
            });
    }

    const renameProject = () => {
        setRenameProjectNameErr(true)
        console.log("renaming project - ", currentProjectId);
        const projectName = document.getElementById('projectName').value;
        axios.post(uriConfig.url.renameProject, { 'name': projectName }, { params: { 'key': currentProjectId }, headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                snackRef.current.snackBarOpen({ 'message': "Project renamed to - '" + projectName + "'!" })
                console.log(response.data)
                getList();
                handleClose();
                requestDialogueClose();
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
                requestDialogueClose();
            });
    }

    const generateUrlRequest = () => {
        console.log("generating url request - ", currentUid);
        // const requestName = document.getElementById('requestName').value;
        axios.get(uriConfig.url.getUser, { headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                const urlEvaluate = generateUrlModalConf(response.data.uid);
                console.log(response.data);
                handleClose();
                openDialog(urlEvaluate);
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
            });
    }

    const handleContextMenu = (event, id) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
        setCurrentId(id); // Set the current ID
        setCurrentProjectId(id)
    };

    const menuEllipsisClick = (event, id, uid) => {
        setAnchorEl(event.currentTarget);
        setRequestNameErr(false);
        setCurrentId(id); // Set the current ID
        setCurrentProjectId(id)
        setCurrentUid(uid) // set the current UID
    }

    const handleClose = (e, value) => {
        setAnchorEl(null);
        setContextMenu(null);
        setRequestNameErr(false);
        setCurrentId(null); // Reset the current ID
        setCurrentProjectId(null)
        setRenameProjectNameErr(false)
        setCurrentUid(null); // Reset the current UID
    };

    const createRequest = () => {
        setRenameNameErr(true)
        const requestName = document.getElementById('requestName').value;
        setItemSkeletonShow(true)
        axios.post(uriConfig.url.newRequest, { 'requestName': requestName, 'projectId': projectId, 'json': placeholderJson }, { headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                snackRef.current.snackBarOpen({ 'message': "New request, '" + requestName + "', created!" })
                console.log(response.data)
                requestDialogueClose()
                getList()
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
            });
    }

    const handleRequestCreation = (e, id) => {
        openDialog(requestModalConf);
        setRequestNameErr(false);
        setProjectId(id);
    }

    const inputErrorCheck = (event, type = 'request') => {
        const inputLength = event.target.value;
        if (inputLength.length < 5 || inputLength.length > 20) {
            if (type === 'rename') { setRenameNameErr(true) }
            else if (type === 'renameProject') { setRenameProjectNameErr(true) }
            else { setRequestNameErr(true) }
        } else {
            if (type === 'rename') { setRenameNameErr(false) }
            else if (type === 'renameProject') { setRenameProjectNameErr(false) }
            else { setRequestNameErr(false) }
        }
    };

    const requestModalConf = {
        title: 'New Request',
        context: 'What is the name of your request?',
        components: <TextField
            autoFocus
            error={requestNameErr}
            margin="dense"
            id="requestName"
            label="Request name"
            fullWidth
            helperText="Request name needs to be between 5 and 20 characters"
            variant="filled"
            defaultValue="Request name"
            onChange={e => inputErrorCheck(e)}
        />,
        cancelButton: 1,
        buttons: {
            bottom: [
                {
                    label: 'Create',
                    disable: requestNameErr,
                    onClick: createRequest
                }
            ]
        }
    };

    const renameModalConf = {
        title: 'Rename Request',
        context: 'Please give a new name for your request.',
        components: <TextField
            autoFocus
            error={renameNameErr}
            margin="dense"
            id="requestName"
            label="Request name"
            fullWidth
            helperText="Request name needs to be between 5 and 20 characters"
            variant="filled"
            defaultValue="Request name"
            onChange={e => inputErrorCheck(e, "rename")}
        />,
        cancelButton: 1,
        buttons: {
            bottom: [
                {
                    label: 'Rename',
                    disable: renameNameErr,
                    onClick: renameRequest
                }
            ]
        }
    };

    const renameProjectModalConf = {
        title: 'Rename Project',
        context: 'Please give a new name for your project.',
        components: <TextField
            autoFocus
            error={renameProjectNameErr}
            margin="dense"
            id="projectName"
            label="Project name"
            fullWidth
            helperText="Project name needs to be between 5 and 20 characters"
            variant="filled"
            defaultValue="Project name"
            onChange={e => inputErrorCheck(e, "renameProject")}
        />,
        cancelButton: 1,
        buttons: {
            bottom: [
                {
                    label: 'Rename Project',
                    disable: renameProjectNameErr,
                    onClick: renameProject
                }
            ]
        }
    };
    const generateUrlModalConf = (apiKey) => {
        const host = window.location.protocol + "//" + window.location.host;
        const curlExample = 'curl -X GET ' + host + '/api/getdata?requestid=' + currentUid + ` -H 'api-key: ` + apiKey + `'`;
        const config = {
            title: 'Direct URL',
            context: 'This url can be used to revoke this api call and retrieve data from the target API. Please make sure you pass the `api-key` headers',
            components: <>
                <Box sx={{ pt: 2 }}>
                    <Typography variant="overline" display="block">URL:</Typography>
                    <CopyBlock
                        CodeBlock
                        text={host + '/api/getdata?requestid=' + currentUid}
                        language={'bash'}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </Box>
                <Box sx={{ py: 2 }}>
                    <Typography variant="overline" display="block">Header:</Typography>
                    <CopyBlock
                        CodeBlock
                        text={'api-key: ' + apiKey}
                        language={'bash'}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </Box>
                <Box sx={{ py: 2 }}>
                    <Typography variant="overline" display="block">cURL example:</Typography>
                    <CodeBlock
                        CodeBlock
                        text={curlExample}
                        language={'bash'}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </Box>
            </>,
            okButton: 1,
            buttons: {
                bottom: [
                ]
            }
        };
        return config;
    }

    useEffect(() => {
        getAccessToken('getList')
    }, []);

    useEffect(() => {
        updateDialog(requestModalConf);
    }, [requestNameErr]);

    useEffect(() => {
        updateDialog(renameModalConf);
    }, [renameNameErr]);

    useEffect(() => {
        updateDialog(requestModalConf);
    }, [projectId]);

    useEffect(() => {
        updateDialog(renameProjectModalConf);
    }, [renameProjectNameErr]);

    useEffect(() =>{        
        if(selectedRequestId > 0){
            navigationConfirmation(null,selectedRequestId)
        }
    },[selectedRequestId])

    // useEffect(() => {
    //     updateDialog(renameProjectModalConf);
    // }, [currentProjectId]);

    useEffect(() => {
        if (caller === 'getList') {
            getList()
            setCaller(null)
        }
    }, [caller]);

    return (
        <>{(listState.length > 0 ? <CustomizedAccordions
            menuList={listState}
            navigationConfirmation={navigationConfirmation}
            handleRequestCreation={handleRequestCreation}
            openDialog={openDialog}
            menuEllipsisClick={menuEllipsisClick}
            generateUrlRequest={generateUrlRequest}
            deleteRequestConfirmation={deleteRequestConfirmation}
            renameModalConf={renameModalConf}
            open={open}
            anchorEl={anchorEl}
            selectedId={selectedId}
            handleClose={handleClose}
            currentId={currentId}
            itemSkeletonShow={itemSkeletonShow}
            projectId={projectId}
            handleContextMenu={handleContextMenu}
            contextMenu={contextMenu}
            renameProjectModalConf={renameProjectModalConf}
            currentProjectId={currentProjectId}
            deleteProjectConfirmation={deleteProjectConfirmation} /> : dataFetched != 1 ?
            <Box>
                <Skeleton width="100%" height={70} />
                <Skeleton width="100%" height={70} />
                <Skeleton width="100%" height={70} />
            </Box> :
            <Box sx={{ 'p': '20px' }}>Create a Project</Box>)}
            <PopSnackBar ref={snackRef} />
        </>
    )
});

export default ProjectMenu;