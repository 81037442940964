import { Container } from '@mui/material';


const ContentContainer = ({children}) => {
    return (
        <Container sx={{
            margin: "10px !important",
            padding: "0px !important"
          }} maxWidth={false}>
            {children}            
        </Container>
    )
};


export default ContentContainer;