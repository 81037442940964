import React, { useState, useEffect } from "react";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { DefaultParams } from "../../data/DummyResponse";
import LoopTabs from "./LoopTabs";
import TypeUrl from "../TripleInput/TripleInput";

const loopOptions = ["none", "url"];

const LoopTypeSelect = (props) => {
    const { requestLoop, objPropUpdateFunc, singlePropUpdateFunc, ...others } = props;
    //const [useLoop, setUseLoop] = useState(false)
    // loop form setter
    const buildQueryLoop = (event) => {
        let newValues = {};
        const loopSelectedType = event.target.checked;
        const url = {
            loop_type: "url",
            filters: {
                level_1: [{
                    "id": 0,
                    "type": "url_parameter",
                    "name": "baseid",
                    "values": ["random_id"]
                }]
            }

        };

        switch (loopSelectedType) {
            case true:
                newValues = url;
                break;
            default:
                newValues = DefaultParams.loop;
        };
        const reBuild = { loop: newValues }
        objPropUpdateFunc(reBuild);
    };

    const renderSwitch = () => {
        switch (requestLoop.loop_type) {
            case "url":
                return <LoopTabs singlePropUpdateFunc={singlePropUpdateFunc} objPropUpdateFunc={objPropUpdateFunc} levelOneArray={requestLoop.filters.level_1} levelTwoArray={requestLoop.filters.level_2} />;
            case "None":
                return <div>None</div>
        }
    }

    return (
        <Stack direction="column" spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
            <FormControl>
                <FormControlLabel
                    control={<Switch
                        size='small'
                        checked={requestLoop.loop_type.toLowerCase() == 'none' ? false : true}
                        onChange={(e) => { buildQueryLoop(e) }}
                        inputProps={{ 'aria-label': 'controlled' }} />}
                    labelPlacement="top"
                    value='url'
                    label="Use Loop"
                    sx={{
                        width: '70px',
                        alignItems: 'start'
                    }}
                />
            </FormControl>
            {renderSwitch()}
        </Stack>
    );
}

export default LoopTypeSelect;