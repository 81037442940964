import React, { useState } from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const StorageConfig = (props) => {
    const { requestStorage, singlePropUpdateFunc, ...other } = props;
    const [showPassword, setShowPassword] = React.useState(false);
    const handleChangeLoop = (e) => {
        let storageTypeEvent = '';
        const changeValue = parseInt(e.target.value)
        if (changeValue === 1) {
            storageTypeEvent = {
                'target': { 'name': 'storage.storage_plan', 'value': 'loop' }
            };
        }
        else {
            storageTypeEvent = { 'target': { 'name': 'storage.storage_plan', 'value': 'single' } };
        }
        
        singlePropUpdateFunc(storageTypeEvent)
        singlePropUpdateFunc(e)
    }


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Stack >
            <FormControl noValidate
                autoComplete="off"
                id='basicForm'>
                <Stack divider={<Divider orientation="horizontal" flexItem />} direction="row" flexWrap={'wrap'} columnGap={2} spacing={0} m={0} p={0} justifyContent="space-between" alignItems="flex-start">
                    <TextField
                        size='small'
                        required
                        name="storage.azure_blob.container_name"
                        value={requestStorage.azure_blob.container_name}
                        onChange={singlePropUpdateFunc}
                        id='containerName'
                        label='Container Name'
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />
                    <TextField
                        size='small'
                        required
                        id='connectionString'
                        label='Connection String'
                        name="storage.azure_blob.connection_string"
                        value={requestStorage.azure_blob.connection_string}
                        onChange={singlePropUpdateFunc}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                        sx={{
                            marginBottom: '1.5rem',
                            flexGrow: 1,
                            minWidth: '40%'
                        }}
                    />
                </Stack>
            </FormControl>
            <Stack mt={2} direction="row" flexWrap={'wrap'} columnGap={2} spacing={0} p={0} justifyContent="space-between" alignItems="flex-start">
                <FormControl>
                    <FormControlLabel
                        control={<Switch
                            name='storage.file.use_loop'
                            value={requestStorage.file.use_loop === 0 ? 1 : 0}
                            size='small'
                            checked={requestStorage.file.use_loop === 0 ? false : true}
                            onChange={handleChangeLoop}
                            inputProps={{ 'aria-label': 'controlled' }} />}
                        labelPlacement="top"
                        value='url'
                        label="Use Loop"
                        sx={{
                            width: '70px',
                            alignItems: 'start'
                        }}
                    />
                </FormControl>
            </Stack>
            <Stack mt={2} direction="row" flexWrap={'wrap'} columnGap={2} spacing={0} p={0} justifyContent="space-between" alignItems="flex-start">
                <TextField
                    size='small'
                    required
                    name="storage.file.file_name"
                    value={requestStorage.file.file_name}
                    onChange={singlePropUpdateFunc}
                    id='fileName'
                    label='File Name'
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth: '40%'
                    }}
                />
                {requestStorage.file.use_loop === 0 ? <TextField
                    size='small'
                    required
                    name="storage.file.format"
                    value={requestStorage.file.format}
                    onChange={singlePropUpdateFunc}
                    id='format'
                    label='Date Format'
                    sx={{
                        marginBottom: '1.5rem',
                        flexGrow: 1,
                        minWidth: '40%'
                    }}
                /> : null}
            </Stack>
        </Stack>

    );
}


export default StorageConfig;