const dev = {
    url: {
        getEnvironment: 'http://localhost:7071/api/getEnvironment',
        getItem: 'http://localhost:7071/api/getItem',
        listAll: 'http://localhost:7071/api/listAll',
        newProject: 'http://localhost:7071/api/newProject',
        newRequest: 'http://localhost:7071/api/newRequest',
        removeItem: 'http://localhost:7071/api/removeItem',
        removeProject: 'http://localhost:7071/api/removeproject',
        renameItem: 'http://localhost:7071/api/renameItem',
        saveRequest: 'http://localhost:7071/api/saveRequest',
        getUser: 'http://localhost:7071/api/getUser',
        getRequestView: 'http://localhost:7071/api/requestview',
        renameProject: 'http://localhost:7071/api/renameProject',
        question: 'http://localhost:7071/api/question'
    }
};

const prod = {
    url: {
        getEnvironment: '/api/getEnvironment',
        getItem: '/api/getItem',
        listAll: '/api/listAll',
        newProject: '/api/newProject',
        newRequest: '/api/newRequest',
        removeItem: '/api/removeItem',
        removeProject: '/api/removeproject',
        renameItem: '/api/renameItem',
        saveRequest: '/api/saveRequest',
        getUser: '/api/getUser',
        getRequestView: '/api/requestview',
        renameProject: '/api/renameProject',
        question: '/api/question'
    }
};

export const uriConfig = process.env.NODE_ENV === "development" ? dev : prod;